body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

.App {
  text-align: center;
}

h1, h2 {
  margin: 30px 0;
}

h1 {
  font-weight: 800;
}

h2 {
  font-weight: 700;
}

p {
  line-height: 1.6;
}
