.testimonial-section {
    padding: 50px 0;
    background: #2e2e2e;
}

.testimonial-section h2 {
    color: #fff;
}

.testimonial-group {
}

.testimonial-item {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
}