.services-section {
  padding: 50px 20px;
  background: url('../../assets/europe-background.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.services-section h2{
  color: #fff;
}


  
.services-list {
  display: flex;
  justify-content: center;
  gap: 20px;
}
  
.service-item {
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.service-item h3 {
  font-weight: 600;
  color: #111862;
  font-size: 1.3rem;
}
  

@media screen and (max-width: 768px) {
  .services-list {
    flex-direction: column;
  }
  .service-item {
    width: 100%;
  }
}