header {
    background: #333;
    color: #FFB129;
    padding: 15px 0;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 14px;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin: 0 15px;
  }
  
  nav ul li a {
    color: #fff;
    text-decoration: none;
  }

  nav ul li a:hover {
    color: #FFB129;
    border-bottom: 1px solid #FFB129;
  }
  