.hero-section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
  }
  
  .hero-content {
    text-align: center;
  }

  .hero-content h1 {
    margin-bottom: 0;
  }
  
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: #FFB129;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
    margin: 20px auto;
  }
  /*  */

  .hero-section {
    position: relative;
    background-color: black;
    height: 75vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  
  .hero-section video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .hero-section .hero-content {
    position: relative;
    z-index: 2;
  }
  
  .hero-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  