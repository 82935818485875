.contact-section {
    padding: 50px 0;
    background: #f9f9f9;
}

.contact-group {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.send-button {
  padding: 10px 20px;
  background: #FCB42B;
  color: #000;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  display: block;
}

.contact-address {
  text-align: left;
}

.success-message {
  text-align: center;
  background-color: #4CAF50;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  margin: 10px auto;
  width: max-content;
}


@media screen and (max-width:768px) {
  .contact-group {
    grid-template-columns: 1fr;
  }
  .contact-address {
    text-align: center;
  }
  .send-button {
    display:inline-block;
  }
}
  